/* You can add global styles to this file, and also import other style files */

html,
body {
  min-height: 100%;
  height: auto;
  margin: 0;
  font-family: Nunito, "Helvetica Neue", sans-serif;
}

body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.row {
  margin: 0 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.rounded-4 {
  border-radius: 0.4rem;
}

.bg-color-primary {
  background-color: #c4a17a !important;
}

.rounded-top {
  border-top-left-radius: 12px !important; /* Adjust the radius value as needed */
  border-top-right-radius: 12px !important;
}

.rounded-top-border {
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}

.s-1rem {
  width: 0.5rem;
  height: 0.5rem;
}

.overlay {
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  width: 100%;
  min-height: 100vh;
  z-index: 10000000;
}

.w-100px {
  width: 100px;
}

.z-index-1 {
  z-index: 1 !important;
}

.z-index-2 {
  z-index: 2 !important;
}

.z-index-3 {
  z-index: 3 !important;
}

.z-index-4 {
  z-index: 4 !important;
}
